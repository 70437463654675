require('jquery')

// TODO: using require.context to import all
//       and store all exported behaviors in a namespace
// Meta programming to export everything from a folder
const ResourceExports = {}
function importAll (r) {
  r.keys().forEach(key => {
    const imported = r(key)
    for (const attrName in imported) {
      // eslint-disable-next-line no-prototype-builtins
      if (imported.hasOwnProperty(attrName)) {
        ResourceExports[attrName] = imported[attrName]
      }
    }
  })
}

importAll(require.context('../resources', true, /\.*(\.js|\.js.erb)$/))

module.exports = ResourceExports
